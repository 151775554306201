import React, { Component } from "react";
import "./footer.css"
import Logo from "../../Image/applogo.png";
import Logotext from "../../Image/apptext.png";

export class Footer extends Component {
  render() {
    return (
      <>
        <footer>
          <div className="Footermain">
           <div >
           <img className="footerlogo" src={Logo} />
           </div>
           <div className="textDiv">
             <p className="footerP">Powered by</p> <br/>
           <img className='textlogo' src={Logotext} />
           </div>
          </div>
         
        </footer>
      </>
    );
  }
}

export default Footer;
