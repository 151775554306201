import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SplitPane from "react-split-pane";
import AppointmentChanges from "../Appointmentchanges/AppointmentChange";
import "./index.css";
import Footer from "../Footer/Footer.js";
import Calendericon from "../../Image/dailyupdate.png";
import Logotext from "../../Image/apptext.png";
import TableComponent from "../TableComponent/index";
//add

const styles = {
  cursor: "col-resize",
  margin: "0px",
  height: "100%",
};

class SideNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routestate: true,
      open: false,
      id: "",
    };
  }
  componentDidMount() {
    console.log("props", this.props);

    console.log(
      "labid",
      typeof JSON.parse(localStorage.getItem("labdetails")).id
    );
  }
  render() {
    let arrayNavigation = [
      {
        name: "Test Results ",
        link: "/testresult",
        img: "patientsearch",
      },
    ];
    let changeValue1 = localStorage.getItem("features");

    if (!changeValue1) {
      window.location.replace("/");
    }

    return (
      <div className="sideNav">
        <Router>
          {/* <SplitPane
            split="vertical"
            minSize={300}
            defaultSize={300}
            resizerStyle={styles}
          > */}
          {/* <menu>
              {arrayNavigation.map((x, index) => (
                <div
                  className="selectedLink"
                  onClick={() => {
                    this.setState({ routestate: false });
                  }}
                >
               
                  <Link
                    to={x.link}
                    exact
                    key={index}
                  >
                    <div className="menuAdj">
                      <div>
                        <img src={`/Image/${x.img}.png`} className="menuImg" />
                      </div>
                      <div style={{ color: '#00AFB9' }}>{x.name}</div>
                    </div>
                  </Link>
                </div>
              ))}
            
            </menu> */}
          <div>
            <Route
              exact
              path="/testresult"
              render={(props) => (
                <AppointmentChanges
                  routestate={this.state.routestate}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/testresult/table"
              render={(props) => (
                <TableComponent routestate={this.state.routestate} {...props} />
              )}
            />
          </div>
          {/* </SplitPane> */}
        </Router>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default SideNav;
