import React, { Component } from "react";
import Header from "../Header/index.js";
import SideNav from "../SideNavigation/index.js";
import Footer from "../Footer/Footer.js";

class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    setTimeout(() => {
      const x = document.cookie;
      if (x === "" && localStorage.getItem("accessToken") == null) {
        localStorage.clear()
        window.location.replace("/");
      }
    }, 1000);
  }

  render() {
    return (
      <>
        <Header />
        <SideNav
          ruleList={this.props.location.state ? this.props.location.state.length > 0 ? this.props.location.state : '' : ''} />
      </>
    );
  }
}

export default Menu;
