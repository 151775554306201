import React, { useEffect, useState } from "react";
// import { useForm } from '@fuse/hooks';
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Multiselect from 'multiselect-react-dropdown';
import { MenuItem, Select, InputLabel, FormControl } from "@material-ui/core";

import { Grid, TextField } from "@material-ui/core";
// import "./TableDash.scss";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../../Service/API/index.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import moment from "moment";
import Viewer, { SpecialZoomLevel, Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";
import Closeicon from "../../Image/close.png";
import TableSortLabel from "@material-ui/core/TableSortLabel";
const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    // maxHeight: 440,
    width: "100%",
  },
});

//Modal Box Material UI
function getModalStyle() {
  const top = 50;
  const left = 50;
  const height = "120vh";

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    height: `100vh`,
  };
}

export default function TableDash(props) {
  let history = useHistory();
  const classes = useStyles();

  const [loader, setloader] = React.useState(false);
  const [listData, setlistData] = React.useState([]);
  const [order, setOrder] = useState("asc");
  const [orderByvalue, setorderByvalue] = React.useState(["sampleCollectedOn-asc"]);
  const [openPDFstate, setopenPDFstate] = React.useState(false);
  const [labelUrl, setlabelUrl] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalcount, setTotalCount] = React.useState();
  let TestTypeData = JSON.parse(localStorage.getItem("testTypes")).length ? JSON.parse(localStorage.getItem("testTypes")) : [{ testName: "Covid-19: BinaxNow" }, { testName: "Covid-19: ID NOW" }, {
    testName: "Send to TPHL"
  }, { testName: "Innova Rapid Antigen Test" }, { testName: "RT-PCR" }]
  const [testTypeList, settestTypeList] = React.useState(TestTypeData)
  const [testTypeValue, settestTypeValue] = React.useState("");
  const [labSiteList, setlabSiteList] = useState(
    JSON.parse(localStorage.getItem("labdetails"))
  );
  const [labSiteName,setlabSiteName]= React.useState("");
  const [testResultOptions,settestResultOptions]= React.useState(['positive', 'negative',  'invalid'])
  const [testResultSelected,settestResultSelected]= React.useState([])
  // Sort array

  const [sortfirstname, setfirstnamesort] = React.useState(false)
  const [sortlastname, setlastnamesort] = React.useState(false)
  const [sortdob, setdobsort] = React.useState(false)
  const [sortmobile, setmobilesort] = React.useState(false)
  const [sortemail, setemailsort] = React.useState(false)
  const [sortlabsite, setlabsitesort] = React.useState(false);
  const [sorttesttype, settesttypesort] = React.useState(false)
  const [sortsampledate, setsampledatesort] = React.useState(false)
  const [sorttestresult, settestresultsort] = React.useState(false)
  const [pageNumber, setPageNumber] = React.useState(1);

  let pageValue = Math.ceil(totalcount / rowsPerPage);

  const handleChangePage = (event, page1) => {
    setPage(page1);
    window.scrollTo(0, 0);
    setlistData([]);

    // getlist();
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageNumber(1);
  };

  const handleChange = (event) => {
    setPageNumber(event.target.value);
    setPage(parseInt(event.target.value) - 1);
    getlist();
  };

  const pageNumberArray = [];
  for (let i = 1; i <= pageValue; i++) {
    pageNumberArray.push(i);
  }


  useEffect(() => {
    setlistData(props.location.state.tabledata);
    setTotalCount(props.location.state.Count);
    getlist();
    getLabname();
  }, [page,rowsPerPage]);

  const Backer = () => {
    props.history.push("/testresult");
  };
  const handleClose = () => {
    setopenPDFstate(false);
  };
  const OpenPDF = (preSignedUrl) => {
    window.open("/pdf", "_blank");
    localStorage.setItem("pdf", preSignedUrl);
    // setlabelUrl(preSignedUrl);
    // setopenPDFstate(true);
  };

  const onSelectChange = (event) => {
    settestResultSelected([...event]);
  };
  const onRemoveChange = (event) => {
    settestResultSelected([...event]);
  };  

const getlist = () => {
    setloader(true);
    setlistData([]);

    let data = {
      firstName: props.location.state.data.firstName,
      lastName: props.location.state.data.lastName,
      dob: props.location.state.data.dob,
      sampleId:props.location.state.data.sampleId,
      sampleCollectedFrom: props.location.state.data.sampleCollectedFrom,
      sampleCollectedTo: props.location.state.data.sampleCollectedTo,
      resultUpdatedOnFrom: props.location.state.data.resultUpdatedOnFrom,
      resultUpdatedOnTo: props.location.state.data.resultUpdatedOnTo,
      labIds: props.location.state.data.labIds,
      pageNo: page,
      pageLimit: rowsPerPage,
      // sortBy: getsortby != null ? getsortby : storesort,
      resultFilter: testResultSelected.length ? testResultSelected : [],
      testTypeFilter: testTypeValue ? [testTypeValue] : [],
      sortBy: orderByvalue,
    };
    api.SearchRecordAPI(data, (response) => {
      if (response.statusCode === 200) {
        setlistData(response.info);
        setloader(false);
        setTotalCount(response.totalListCount);
      } else {
        toast.error(response.errorMessage);
        setloader(false);
        setlistData([]);
        setTotalCount("");
      }
    });
  };

  const testTypeOnChange = (e) => {
    settestTypeValue(e.target.value);
  };
  // const testResultOnChange = (e) => {
  //   settestResultValue(e.target.value);
  // };
  const getLabname= ()=>{
    if(props.location.state.data.labIds.length){
      labSiteList.filter((item)=>{
       if( item.id==props.location.state.data.labIds[0]){
        setlabSiteName(item.organizationName)
       }
      })
    }
  }

  //---Searchby onClick ---//
  const SearchByValue = (e) => {
    setPage(0);
    setlistData([]);
    setloader(true);
    let data = {
      firstName: props.location.state.data.firstName,
      lastName: props.location.state.data.lastName,
      dob: props.location.state.data.dob,
      sampleCollectedFrom: props.location.state.data.sampleCollectedFrom,
      sampleCollectedTo: props.location.state.data.sampleCollectedTo,
      resultUpdatedOnFrom: props.location.state.data.resultUpdatedOnFrom,
      resultUpdatedOnTo: props.location.state.data.resultUpdatedOnTo,
      labIds: props.location.state.data.labIds,
      pageNo: 0,
      pageLimit: rowsPerPage?rowsPerPage:10,
      resultFilter: testResultSelected.length ? testResultSelected : [],
      testTypeFilter: testTypeValue ? [testTypeValue] : [],
      sortBy: orderByvalue,
    };
    api.SearchRecordAPI(data, (response) => {
      if (response.statusCode === 200) {
        setlistData(response.info);
        setloader(false);
        setTotalCount(response.totalListCount);
      } else {
        toast.error(response.errorMessage);
        setloader(false);
        setlistData([]);
        setTotalCount("");
      }
    });
  };

  const Resetbyvalue = () => {
    setPage(0);
    setTotalCount("0");
    setloader(true);
    settestTypeValue("");
    settestResultSelected([]);
    setOrder("asc");
    setlistData([]);
    setorderByvalue(["sampleCollectedOn-asc"]);
    setRowsPerPage(10);
    setPageNumber(1);

    let data = {
      firstName: props.location.state.data.firstName,
      lastName: props.location.state.data.lastName,
      dob: props.location.state.data.dob,
      sampleCollectedFrom: props.location.state.data.sampleCollectedFrom,
      sampleCollectedTo: props.location.state.data.sampleCollectedTo,
      resultUpdatedOnFrom: props.location.state.data.resultUpdatedOnFrom,
      resultUpdatedOnTo: props.location.state.data.resultUpdatedOnTo,
      labIds: props.location.state.data.labIds,
      pageNo: page,
      pageLimit:10,
      resultFilter: [],
      testTypeFilter: [],
      sortBy: ["sampleCollectedOn-asc"],
    };

    api.SearchRecordAPI(data, (response) => {
      if (response.statusCode === 200) {
        setlistData(response.info);
        setloader(false);
        setTotalCount(response.totalListCount);
      } else {
        setloader(false);
        setlistData([]);
        setTotalCount("");
      }
    });
  };

  const createSortHandler = (id) => {
    let spiltArr = id.split('-')
    console.log('spiltArr', spiltArr)
    let spiltArr_zero = spiltArr[0]

    let orderspread = Array.from(new Set([...orderByvalue, id]));
    console.log('-->', orderspread)

    if (spiltArr_zero) {
      let d = []
      orderspread.map((item, index) => {
        console.log("item", item)

        let ab = item.split('-')
        let c = ab[0]
        console.log("c", c)
        if (c === spiltArr_zero) {
          orderspread[index] = id
        }
      })

    }
    let duplicateCheck = Array.from(new Set(orderspread.map(JSON.stringify))).map(JSON.parse);
    console.log("check====", duplicateCheck)
    setorderByvalue(duplicateCheck);
    setOrder(order === "asc" ? "desc" : "asc");
    setloader(true);
    setlistData([]);
    let data = {
      firstName: props.location.state.data.firstName,
      lastName: props.location.state.data.lastName,
      dob: props.location.state.data.dob,
      sampleCollectedFrom: props.location.state.data.sampleCollectedFrom,
      sampleCollectedTo: props.location.state.data.sampleCollectedTo,
      resultUpdatedOnFrom: props.location.state.data.resultUpdatedOnFrom,
      resultUpdatedOnTo: props.location.state.data.resultUpdatedOnTo,
      labIds: props.location.state.data.labIds,
      pageNo: page,
      pageLimit: rowsPerPage?rowsPerPage:10,
      resultFilter:  testResultSelected.length ? testResultSelected : [],
      testTypeFilter: testTypeValue ? [testTypeValue] : [],
      sortBy: duplicateCheck,
    };

    api.SearchRecordAPI(data, (res) => {
      if (res.statusCode === 200) {
        setloader(false);
        setlistData(res.info);
        setTotalCount(res.totalListCount);
      } else {
        setloader(false);
        setlistData([]);
        setTotalCount("");
      }
    });
  };
  // Sorting
  const firstnameSortHandler = () => {
    setfirstnamesort(!sortfirstname)
    setloader(true)
    let senddata = sortfirstname == true ? 'firstName-asc' : "firstName-desc"
    createSortHandler(senddata)

  }
  const lastnameSortHandler = (id) => {
    setlastnamesort(!sortlastname)
    setloader(true)
    let senddata = sortlastname == true ? 'lastName-asc' : "lastName-desc"
    createSortHandler(senddata)
  }
  const dobSortHandler = () => {
    setdobsort(!sortdob)
    setloader(true)
    let senddata = sortdob == true ? 'dob-asc' : "dob-desc"
    createSortHandler(senddata)
  }
  const mobileSortHandler = () => {
    setmobilesort(!sortmobile)
    setloader(true)
    let senddata = sortmobile == true ? 'mobileNumber-asc' : "mobileNumber-desc"
    createSortHandler(senddata)
  }
  const emailSortHandler = () => {
    setemailsort(!sortemail)
    setloader(true)
    let senddata = sortemail == true ? 'email-asc' : "email-desc"
    createSortHandler(senddata)
  }

  const LabsiteHandler = (id) => {
    setlabsitesort(!sortlabsite)
    setloader(true)
    let senddata = sortlabsite == true ? 'labSite-asc' : "labSite-desc"
    createSortHandler(senddata)
  }
  const testtypeHandler = () => {
    settesttypesort(!sorttesttype)
    setloader(true)
    let senddata = sorttesttype == true ? 'testType-asc' : "testType-desc"
    createSortHandler(senddata)
  }
  const SampleCollectionHandler = (id) => {
    setsampledatesort(!sortsampledate)
    setloader(true)
    let senddata = sortsampledate == true ? 'sampleCollectedOn-asc' : "sampleCollectedOn-desc"
    createSortHandler(senddata)
  }
  const testresulthandler = (id) => {
    settestresultsort(!sorttestresult)
    setloader(true)
    let senddata = sorttestresult == true ? 'testResult-asc' : "testResult-desc"
    createSortHandler(senddata)
  }

  // Modal styles----------------------------------------------------------------------------------------------
  const customStylesDelete = {
    content: {
      top: "40%",
      height: "24%",
      backgroundColor: "white",
      border: "none",
      outline: "none",
      width: "70%",
      marginLeft: "15%",
    },
  };

  return (
    <>
      <ToastContainer />
      {openPDFstate == true ? (
        <Modal
          isOpen={openPDFstate}
          onClose={handleClose}
          style={customStylesDelete}
          contentLabel="Example Modal"
          className="shedule"
        >
          <div
            className="container-fluid"
            style={{
              backgroundColor: "#6fe2e9",
              padding: "1%",
              width: "100%",
              marginTop: "5%",
              borderRadius: "10px",
              height: "85vh",
              boxShadow:
                "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086),0 100px 80px rgba(0, 0, 0, 0.12)",
            }}
          >
            <div className="close" onClick={handleClose} className="pdfclose">
              <img src={Closeicon} className="closeicon" />
            </div>
            <div className="Pdfhead">COVID - 19 Result</div>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
              <div className="pdfbody">
                <Viewer zoom={1} fileUrl={labelUrl} />
              </div>
            </Worker>
          </div>
        </Modal>
      ) : (
        ""
      )}

      <Grid container className="gridcontent">
        {loader === true ? (
          <Paper
            className={classes.root}
            style={{
              // marginTop: "2em",
              width: "90%",
              margin: "2em auto",
              boxShadow: "unset !important",
            }}
          >
            <div className="loaderAdj">
              <CircularProgress />
            </div>
          </Paper>
        ) : (
          <>
            <Grid item xs={12} md={12} sm={12}>
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  justifyContent: "space-between",
                }}
              >
                <div className="SearchHeader">
                  <div className="SearchHeaderlabel">Search Results​:</div>
                  <div className="SearchParams">
                    {props.location.state.data.firstName ? (
                      <div className="SearchParam">
                       <span className="SearchParamValue"> First Name:</span> {props.location.state.data.firstName}
                      </div>
                    ) : null}
                    {props.location.state.data.lastName ? (
                      <div className="SearchParam">
                       <span className="SearchParamValue"> Last Name:</span> {props.location.state.data.lastName}
                      </div>
                    ) : null}
                    {props.location.state.data.dob ? (
                      <div className="SearchParam">
                        <span className="SearchParamValue"> DOB: </span>{moment(props.location.state.data.dob).format("MM/DD/yyyy")}
                      </div>
                    ) : null}
                    {props.location.state.data.labIds.length ? (
                      <div className="SearchParam"><span className="SearchParamValue">Lab Site:</span>{labSiteName} </div>
                    ) : null}
                    {props.location.state.data.sampleCollectedFrom ? (
                      <div className="SearchParam">
                       <span className="SearchParamValue"> Sample Colleceted Date: From:</span>
                        {props.location.state.data.sampleCollectedFrom} <span className="SearchParamValue"> - To:</span>
                        {props.location.state.data.sampleCollectedTo}
                      </div>
                    ) : null}
                    {props.location.state.data.resultUpdatedOnFrom ? (
                      <div className="SearchParam">
                       <span className="SearchParamValue"> Result Report Date: From:</span>
                        {props.location.state.data.resultUpdatedOnFrom} <span className="SearchParamValue"> - To:</span>
                        {props.location.state.data.resultUpdatedOnTo}
                      </div>
                      ) : null}
                      {props.location.state.data.sampleId ? (
                        <div className="SearchParam">
                          <span className="SearchParamValue"> Sample Id:</span>
                          {props.location.state.data.sampleId}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="backer">
                  <p onClick={Backer} className="linkBack">
                    Back{" "}
                  </p>
                </div>
              </div>

              <Grid container spacing={1} style={{ marginTop: "10px",width:"98vw" }}>
                <Grid item xs={12} md={2} sm={12}>
                  <div className="search_label">Filter by:</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  sm={12}
                  style={{ marginRight: "15px" }}
                >
                  <FormControl className="TestTypegrid1">
                    <InputLabel className="SearchLabel">Test Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={testTypeValue}
                      name="testTypeValue"
                      onChange={testTypeOnChange}
                      required
                    >
                      {testTypeList.map((item) => (
                        <MenuItem value={item.testName}>
                          {item.testName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3} sm={12}>
                  <Multiselect
                    showArrow
                    selectedValues={testResultSelected} 
                    options={testResultOptions}
                    isObject={false}
                    placeholder="Test Result"
                    onSelect={onSelectChange}
                    onRemove={onRemoveChange}
                    showCheckbox
                    
                  />
                </Grid>
                <Grid item xs={12} md={4} sm={12}>
                  <div className="searchRow">
                    <div style={{ marginRight: "20px" }}>
                      <button className="srchbtn" onClick={SearchByValue}>
                        Search
                      </button>
                    </div>
                    <div style={{ marginLeft: "20px" }}>
                      <button className="srchbtn" onClick={Resetbyvalue}>
                        Reset
                      </button>
                    </div>
                  </div>
                </Grid>
              </Grid>

              <Paper
                className={classes.root}
                style={{
                  // marginTop: "2em",
                  width: "94%",
                  margin: "2em auto",
                }}
              >
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <TableSortLabel
                            direction={sortfirstname == false ? 'asc' : 'desc'}
                            onClick={firstnameSortHandler}
                            active={orderByvalue.includes("firstName-asc") || orderByvalue.includes("firstName-desc")}
                          // direction={order === "asc" ? "asc" : "desc"}
                          // onClick={() => createSortHandler("firstName")}
                          >
                            First Name
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={orderByvalue.includes("lastName-asc") || orderByvalue.includes("lastName-desc")}
                            // direction={order === "asc" ? "asc" : "desc"}
                            // onClick={() => createSortHandler("lastName")}
                            direction={sortlastname == false ? 'asc' : 'desc'}
                            onClick={lastnameSortHandler}
                          >
                            Last Name
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={orderByvalue.includes("dob-asc") || orderByvalue.includes("dob-desc")}
                            // direction={order === "asc" ? "asc" : "desc"}
                            // onClick={() => createSortHandler("dob")}
                            direction={sortdob == false ? 'asc' : 'desc'}
                            onClick={dobSortHandler}
                            style={{
                              color: "black",
                            }}
                          >
                            Date of Birth (MM/DD/YYYY)
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={orderByvalue.includes("mobileNumber-asc") || orderByvalue.includes("mobileNumber-desc")}
                            // direction={order === "asc" ? "asc" : "desc"}
                            // onClick={() => createSortHandler("mobileNumber")}
                            direction={sortmobile == false ? 'asc' : 'desc'}
                            onClick={mobileSortHandler}
                            style={{
                              color: "black",
                            }}
                          >
                            Phone Number
                          </TableSortLabel>
                        </TableCell>

                        <TableCell>
                          <TableSortLabel
                            active={orderByvalue.includes("email-asc") || orderByvalue.includes("email-desc")}
                            // direction={order === "asc" ? "asc" : "desc"}
                            // onClick={() => createSortHandler("email")}
                            direction={sortemail == false ? 'asc' : 'desc'}
                            onClick={emailSortHandler}
                            style={{
                              color: "black",
                            }}
                          >
                            Email Address (if not NA)
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={orderByvalue.includes("labSite-asc") || orderByvalue.includes("labSite-desc")}
                            // direction={order === "asc" ? "asc" : "desc"}
                            // onClick={() => createSortHandler("labSite")}
                            direction={sortlabsite == false ? 'asc' : 'desc'}
                            onClick={LabsiteHandler}
                            style={{
                              color: "black",
                            }}
                          >
                            Lab Site
                          </TableSortLabel>
                        </TableCell>

                        <TableCell>
                          <TableSortLabel
                            active={orderByvalue.includes("testType-asc") || orderByvalue.includes("testType-desc")}
                            // direction={order === "asc" ? "asc" : "desc"}
                            // onClick={() => createSortHandler("testType")}
                            direction={sorttesttype == false ? 'asc' : 'desc'}
                            onClick={testtypeHandler}
                            style={{
                              color: "black",
                            }}
                          >
                            Test Type
                          </TableSortLabel>
                        </TableCell>

                        <TableCell>
                          <TableSortLabel
                            active={orderByvalue.includes("sampleCollectedOn-asc") || orderByvalue.includes("sampleCollectedOn-desc")}
                            // direction={order === "asc" ? "asc" : "desc"}
                            // onClick={() =>
                            //   createSortHandler("sampleCollectedOn")
                            // }
                            direction={sortsampledate == false ? 'asc' : 'desc'}
                            onClick={SampleCollectionHandler}
                            style={{
                              color: "black",
                            }}
                          >
                            Sample Collection Date (MM/DD/YYYY)
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>Sample Id</TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={orderByvalue.includes("testResult-asc") || orderByvalue.includes("testResult-desc")}
                            // direction={order === "asc" ? "asc" : "desc"}
                            // onClick={() => createSortHandler("testResult")}
                            direction={sorttestresult == false ? 'asc' : 'desc'}
                            onClick={testresulthandler}
                            style={{
                              color: "black",
                               width: "80px"
                            }}
                          >
                            Test Result
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>PDF</TableCell>
                        <TableCell>Vaccination Status</TableCell>
                        
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listData !== undefined ? (
                        listData.map((row) => (
                          <TableRow>
                            <TableCell
                              align="center"
                              style={{ verticalAlign: "baseline" }}
                            >
                              {row.firstName}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ verticalAlign: "baseline" }}
                            >
                              {row.lastName}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ verticalAlign: "baseline" }}
                            >
                              {moment(row.dob).format("MM/DD/yyyy")}
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{ verticalAlign: "baseline" }}
                            >
                              {row.mobileNumber}
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{ verticalAlign: "baseline" }}
                            >
                              {row.email}
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{ verticalAlign: "baseline" }}
                            >
                              {row.labName}
                            </TableCell>

                            <TableCell align="center">{row.testType}</TableCell>

                            <TableCell
                              align="center"
                              style={{ verticalAlign: "baseline" }}
                            >
                              {moment(row.sampleCollectedOn).format(
                                "MM/DD/yyyy"
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ verticalAlign: "baseline" }}
                            >
                              {row.sampleId}
                            </TableCell>
                            <TableCell >
                              <span
                                className={
                                  row.testResult == "Invalid"
                                    ? "yellowdot"
                                    : row.testResult == "Not Detected"
                                    ? "greendot"
                                    : row.testResult == "Detected"
                                    ? "reddot"
                                    : ""
                                }
                              ></span>
                              <span>
                                {row.testResult == "Detected"
                                  ? "Positive"
                                  : row.testResult == "Not Detected"
                                  ? "Negative"
                                  : "Invalid"}
                              </span>
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ verticalAlign: "baseline" }}
                            >
                              {row.preSignedUrl ? (
                                <i
                                  onClick={() => {
                                    OpenPDF(row.preSignedUrl);
                                  }}
                                  className="fa fa-file-pdf-o"
                                  style={{
                                    fontSize: "20px",
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                ></i>
                              ) : (
                                ""
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ verticalAlign: "baseline" }}
                            >
                              {row.vaccinationStatus}
                            </TableCell>
                            
                          </TableRow>
                          
                        ))
                      ) : (
                        <div>No Records Found</div>
                      )}
                    </TableBody>
                  </Table>
                  </TableContainer>
                  <div className="pageNumberClass">
                  <div>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}
                      component="div"
                      count={totalcount !== undefined ? totalcount : ""}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                  <div className="pageNumber-label">
                    <FormControl>
                      <div className="selectPage-class">
                        <div className="selectPage-label">Go to page: </div>
                        <div className="selectPage-dropdown">
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            className="label-pageSelect"
                            value={pageNumber}
                            label="Select page"
                            onChange={handleChange}
                            sx={{ width: "60px" }}
                          >
                            {pageNumberArray.map((index, key) => (
                              <MenuItem value={index} indexValue={key}>
                                {index}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </FormControl>
                  </div>
                </div>
              </Paper>
            </Grid>
          </>
        )}
      </Grid>
      {/* <Footer /> */}
    </>
  );
}
