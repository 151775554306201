import React, { useEffect, useState } from "react";
import "./index.css";
import eye from "../../Image/eye.png";
import "react-datepicker/dist/react-datepicker.css";
import api from "../../Service/API/index";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress, Grid } from "@material-ui/core";
import moment from "moment";
import Paper from "@material-ui/core/Paper";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import DateFnsUtils from "@date-io/date-fns";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { utils } from "react-modern-calendar-datepicker";
import DatePicker from "react-modern-calendar-datepicker";
import "react-google-flight-datepicker/dist/main.css";

const AppointmentChange = (props) => {
  const [isLoaded, setisLoaded] = useState(false);

  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [labSite, setlabSite] = useState("");
  const [labSiteList, setlabSiteList] = useState(
    JSON.parse(localStorage.getItem("labdetails"))
  );

  const [dobDate, setdobDate] = useState(null);
  const defaultValue = {
    from: null,
    to: null,
  };
  const defaultValueReport = {
    from: null,
    to: null,
  };
  const [selectedDayRange, setSelectedDayRange] = useState(defaultValue);
  const [ReportDateRange, setReportDateRange] = useState(defaultValueReport);
  const [sampleId, setsampleId] = useState("");
  const handleDateChange = (date) => {
    setdobDate(date);
  };
  const SignInC = (evt) => {
    if (evt.keyCode == 13) {
      onSearch();
    }
  };

  const onSearch = () => {
    if (dobDate !== null && dobDate.getFullYear() <= "1899") {
      toast.error("Please enter valid DOB");
      return 0;
    } else if (
      dobDate == "Invalid Date" ||
      moment(dobDate).format("yyyy") <= "1899" == true ||
      dobDate > new Date() == true
    ) {
      toast.error("Please enter valid DOB");
      return 0;
    }

    else if (
      dobDate === null &&
      lastName.trim() == "" &&
      firstName.trim() == "" &&
      labSite === "" &&
      sampleId===""&&
      selectedDayRange.from == null &&
      selectedDayRange.to == null &&
      ReportDateRange.from == null &&
      ReportDateRange.to == null
    ) {
      toast.error("Please enter the data");
    } else {
      setisLoaded(true);

      let data = {
        firstName: firstName.trim()?firstName.trim():null,
        lastName: lastName.trim()?lastName.trim():null,
        dob: dobDate === null ? "" : moment(dobDate).format("yyyy-MM-DD"),
        sampleCollectedFrom: selectedDayRange.from
          ? `${selectedDayRange.from.year}-${selectedDayRange.from.month < 10
            ? "0" + selectedDayRange.from.month
            : selectedDayRange.from.month
          }-${selectedDayRange.from.day < 10
            ? "0" + selectedDayRange.from.day
            : selectedDayRange.from.day
          }`
          : null,
        sampleCollectedTo: selectedDayRange.to
          ? `${selectedDayRange.to.year}-${selectedDayRange.to.month < 10
            ? "0" + selectedDayRange.to.month
            : selectedDayRange.to.month
          }-${selectedDayRange.to.day < 10
            ? "0" + selectedDayRange.to.day
            : selectedDayRange.to.day
          }`
          : null,
        resultUpdatedOnFrom: ReportDateRange.from
          ? `${ReportDateRange.from.year}-${ReportDateRange.from.month < 10
            ? "0" + ReportDateRange.from.month
            : ReportDateRange.from.month
          }-${ReportDateRange.from.day < 10
            ? "0" + ReportDateRange.from.day
            : ReportDateRange.from.day
          }`
          : null,
        resultUpdatedOnTo: ReportDateRange.to
          ? `${ReportDateRange.to.year}-${ReportDateRange.to.month < 10
            ? "0" + ReportDateRange.to.month
            : ReportDateRange.to.month
          }-${ReportDateRange.to.day < 10
            ? "0" + ReportDateRange.to.day
            : ReportDateRange.to.day
          }`
          : null,
        labIds: labSite ? [labSite] : [],
        sampleId: sampleId,
        pageNo: 0,
        pageLimit: 10,
        resultFilter: [],
        testTypeFilter: [],
        sortIn: "asc",
        sortBy: ["firstName"],
      };
      console.log("red",data)
      api.SearchRecordAPI(data, (response) => {
        if (response.statusCode === 200) {
          props.history.push({
            pathname: "/testresult/table",
            state: {
              tabledata: response.info,
              data: data,
              Count: response.totalListCount,
            },
          });
        } else if (response.errorCode == 400) {
          setisLoaded(false);
          toast.error(response.errorMessage);
        }
      });
    }
  };

  //===Date format function===//
  const formatDateInput = () => {
    if (!selectedDayRange.from) return "";
    if (!selectedDayRange.to) return "";
    // if(ReportDateRange.from) return "";
    // if(ReportDateRange.to) return "";
    const toDate = selectedDayRange.to;
    const fromDate = selectedDayRange.from;
    const toYear = toDate.year.toString();
    const toMonth = toDate.month.toString().padStart(2, 0);
    const toDay = toDate.day.toString().padStart(2, 0);
    const fromYear = fromDate.year.toString();
    const fromMonth = fromDate.month.toString().padStart(2, 0);
    const fromDay = fromDate.day.toString().padStart(2, 0);
    return `${moment(fromMonth)
      .format("MMM")
      .toLocaleUpperCase()} ${fromDay},${fromYear} - ${moment(toMonth)
        .format("MMM")
        .toLocaleUpperCase()} ${toDay},${toYear}`;
  };

  // 
  const ReportDateInput = () => {
    if (!ReportDateRange.from) return "";
    if (!ReportDateRange.to) return "";
    const toDate1 = ReportDateRange.to;
    const fromDate1 = ReportDateRange.from;
    const toYear1 = toDate1.year.toString();
    const toMonth1 = toDate1.month.toString().padStart(2, 0);
    const toDay1 = toDate1.day.toString().padStart(2, 0);
    const fromYear1 = fromDate1.year.toString();
    const fromMonth1 = fromDate1.month.toString().padStart(2, 0);
    const fromDay1 = fromDate1.day.toString().padStart(2, 0);
    return `${moment(fromMonth1)
      .format("MMM")
      .toLocaleUpperCase()} ${fromDay1},${fromYear1} - ${moment(toMonth1)
        .format("MMM")
        .toLocaleUpperCase()} ${toDay1},${toYear1}`;
  };
  return (
    <div className="container">
      <ToastContainer />

      {isLoaded === true ? (
        <Paper
          // className={classes.root}
          style={{
            // marginTop: "2em",
            width: "90%",
            margin: "2em auto",
            boxShadow: "unset !important",
          }}
        >
          <div className="loaderAdj">
            <CircularProgress />
          </div>
        </Paper>
      ) : (
        <div style={{ marginTop: "80px" }}>
          {labSiteList.length?(<div>
            <header>
              <h1>Search</h1>
            </header>

            <div>
              <Grid container spacing={2} style={{ marginTop: "35px" }}>
                <Grid item xs={12} md={6} sm={12}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label className="labelStyle">
                      Date of Birth (MM/DD/YYYY)
                    </label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        placeholder="MM/DD/YYYY"
                        clearable
                        value={dobDate}
                        format="MM/dd/yyyy"
                        maxDate={new Date()}
                        required
                        autoOk
                        // onKeyUp={this.EnterKeynext.bind(this)}
                        onChange={(date) => handleDateChange(date)}
                        onKeyUp={SignInC}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label className="labelStyle">Lab Site </label>
                    <select
                      className="PatientSelect"
                      value={labSite}
                      onChange={(e) => setlabSite(e.target.value)}
                    >
                      <option value="">All lab sites </option>
                      {labSiteList.map((name) => (
                        <option value={name.labId} key={name.labId}>
                          {name.facilityName}{" "}
                        </option>
                      ))}
                    </select>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label className="labelStyle">First Name</label>
                    <input
                      type="text"
                      className="searchTextInput"
                      value={firstName}
                      onChange={(e) => setfirstName(e.target.value)}
                      onKeyUp={SignInC}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6} sm={12}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label className="labelStyle">Last Name</label>
                    <input
                      type="text"
                      className="searchTextInput"
                      value={lastName}
                      onChange={(e) => setlastName(e.target.value)}
                      onKeyUp={SignInC}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6} sm={12}>
                  <label className="labelStyle">Sample Collection Date</label>{" "}
                  <br />
                  <div>
                    <DatePicker
                      inputPlaceholder="Date range"
                      value={selectedDayRange}
                      onChange={setSelectedDayRange}
                      maximumDate={utils().getToday()}
                      colorPrimary="#00afb9"
                      colorPrimaryLight="#a1eaee"
                      formatInputText={formatDateInput}
                      renderFooter={() => (
                        <div
                          style={{
                            marginTop: "-20px",
                            display: "flex",
                            justifyContent: "center",
                            padding: "1rem 2rem",
                          }}
                        >
                          <button
                            type="button"
                            onClick={() => {
                              setSelectedDayRange({
                                from: null,
                                to: null,
                              });
                            }}
                            style={{
                              border: "#0fbcf9",
                              backgroundColor: "#f07167",
                              color: "#fff",
                              borderRadius: "0.5rem",
                              padding: "0.5rem 1rem",
                            }}
                          >
                            Reset
                          </button>
                        </div>
                      )}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <label className="labelStyle">Result Report Date</label>{" "}

                  <div>
                    <DatePicker
                      inputPlaceholder="Date range"
                      value={ReportDateRange}
                      onChange={setReportDateRange}
                      maximumDate={utils().getToday()}
                      colorPrimary="#00afb9"
                      colorPrimaryLight="#a1eaee"
                      formatInputText={ReportDateInput}
                      renderFooter={() => (
                        <div
                          style={{
                            marginTop: "-40px",
                            display: "flex",
                            justifyContent: "center",
                            padding: "1rem 2rem",
                          }}
                        >
                          <button
                            type="button"
                            onClick={() => {
                              setReportDateRange({
                                from: null,
                                to: null,
                              });
                            }}
                            style={{
                              border: "#0fbcf9",
                              backgroundColor: "#f07167",
                              color: "#fff",
                              borderRadius: "0.5rem",
                              padding: "0.5rem 1rem",
                            }}
                          >
                            Reset
                          </button>
                        </div>
                      )}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label className="labelStyle">Sample Id</label>
                    <input
                      type="text"
                      className="searchTextInput"
                      value={sampleId}
                      onChange={(e) => setsampleId(e.target.value)}
                      onKeyUp={SignInC}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button className="NextBtn1" onClick={onSearch}>
                Search
              </button>
            </div>
          </div>):<div style={{textAlign:"center"}}><h3>No labs found for reporting. Please contact administrator for lab assign</h3></div>}
        </div>
      )}
    </div>
  );
};

export default AppointmentChange;
