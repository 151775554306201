import React, { Component } from "react";
import Viewer, { SpecialZoomLevel, Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
class Pdfview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            setpdfurl:''
        }
    }
    render() {
        return (
            <>
                <div>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
                        <div className="pdfbody" >
                            <Viewer zoom={1} fileUrl={localStorage.getItem('pdf')
} />
                        </div>
                    </Worker>
                </div>
            </>
        );
    }
}

export default Pdfview;
