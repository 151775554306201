import React, { Component } from "react";
import "../Login/index.css";
import Logo from "../../Image/applogo.png";
import Logotext from "../../Image/apptext.png";
import Logo1 from "../../Image/BVI_footer.png";
import Shield from "../../Image/VITEMA.png";
import Info from "../../Image/info.png";
import api from '../../Service/API/index.js';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import Footer from '../Footer/Footer.js'
import Tooltip from '@material-ui/core/Tooltip';



class LoginClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      RadioLoginType: "email",
      inputemail: "",
      Inputvalid: false,
      from: "noselect"
    };
  }
  // Radio button onchange
  componentDidMount() {
    if (localStorage.getItem('features') == "menu") {
      if (window.location.pathname == "/") {
        this.setState({isLoaded:true})
        window.location.replace("/testresult");
      }
    }
  }
  handleChange(event) {
    this.setState({
      RadioLoginType: event.target.value,
    });
  }
  // Email input onchange
  HandleInputChange(e) {
    this.setState({
      inputemail: e.target.value,
    });
  }
  SubmitLogin() {

    if (this.state.inputemail.trim() != "") {
      if ((/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(this.state.inputemail.trim())) == false && this.state.inputemail.trim() != '') {
        toast.error("Enter the correct Email-id")
      }
      else {

        let data = {
          "email": this.state.inputemail.trim(),
          "messageCode": ""
        }

        this.setState({
          isLoaded: true
        })
        api.LoginAPI(data, (response) => {

          if (response.statusCode === 200) {
            localStorage.setItem("messageId", (response.messageId));
            this.props.history.push(
              {
                pathname: '/otp',
                state: { from: this.state.from }
              }
            )

            this.setState({
              isLoaded: false
            })
          }
          else if (response.errorCode === 400) {
            toast.error(response.errorMessage)
            this.setState({
              isLoaded: false
            })
          }



        })
      }

    }
    else if (this.state.inputemail.trim() == "") {
      toast.error("Please enter an email address")
    }


  }
  SignInC(evt) {
    if (evt.keyCode == 13) {
      this.SubmitLogin()
    }
  }
  // ---------------------------------------------------------------------------------------------------------
  render() {
    // window.scrollTo(0, 0);

    return (
      <div className="loginAdj">
        <ToastContainer />
        {this.state.isLoaded === true ? (
          <div className="loaderAdj">
            <CircularProgress />
          </div>
        ) :
          <div className="loginBG">
            <div className="loginLogo">
              <div>
                <img src={Logo} className="logo" />
              </div>

              <div>
                <img src={Logotext} className="logotext" />
              </div>
              {/* <br /> */}
            </div>

            <div className="welcome">
              <div style={{ display: 'flex',marginLeft:'-10px' }}>
                <div>
                  {/* <img src={Shield} style={{ width: '90px', marginLeft: '10px' }} className="logo1" /> */}
                </div>
                <div style={{ marginTop: '-10px' }}>
                  <img src={Logo1} className="logo1" />
                </div>
              </div>
              <h3>Welcome to the</h3>
              <h4>Reporting Gateway​</h4>
            </div>

            <div className="inputDiv">
              <div style={{ display: "flex", marginLeft: "1%" }}>
                <div>
                  <p>Please enter your email address to login</p>
                </div>
                <div>
                  <Tooltip title="This is where you will receive your secure code for log-in" placement="top">
                    <img src={Info} className="Info" />
                  </Tooltip>

                </div>
              </div>
              <div>
                <input
                  type="text"
                  className="textInput"
                  placeholder="Enter your email address"
                  style={{ width: '82%', marginLeft: '1%', paddingLeft: '0px' }}
                  value={this.state.inputemail}
                  onChange={(e) =>
                    this.setState({ inputemail: e.target.value })
                  }
                  onKeyUp={this.SignInC.bind(this)}
                />

              </div>
              <div className="centerAdj">
                <button className="subBtn" onClick={this.SubmitLogin.bind(this)}>Submit</button>
              </div>
            </div>
          </div>
        }
        {/* <div className="footeerlogin" >
          <Footer />

        </div> */}
      </div >

    );
  }
}
export default LoginClass;
